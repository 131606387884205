import {CacheProvider} from '@emotion/react';
import {RemixBrowser} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import React, {useState} from 'react';
import * as ReactDOM from 'react-dom/client';
import {bootstrap} from '#/app/bootstrap';
import createEmotionCache, {defaultCache} from '#/app/helpers/Emotion/cache';
import {ClientStyleContext} from '#/app/helpers/Emotion/context';

Sentry.init({
  dsn: 'https://89df23dab75d0394243e4c736dfcfdd2@o4508057851854848.ingest.us.sentry.io/4508057854345216',
});

bootstrap();
interface ClientCacheProviderProps {
  children: React.ReactNode;
}

function ClientCacheProvider({children}: ClientCacheProviderProps) {
  const [cache, setCache] = useState(defaultCache);

  function reset() {
    setCache(createEmotionCache());
  }

  return (
    <ClientStyleContext.Provider value={{reset}}>
      <CacheProvider value={cache}>{children}</CacheProvider>
    </ClientStyleContext.Provider>
  );
}

const hydrate = () => {
  React.startTransition(() => {
    ReactDOM.hydrateRoot(
      document,
      <ClientCacheProvider>
        <RemixBrowser />
      </ClientCacheProvider>,
    );
  });
};

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari doesn't support requestIdleCallback
  // https://caniuse.com/requestidlecallback
  setTimeout(hydrate, 1);
}
